<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
<!--            <img src="assets/images/logo-light.svg" alt="" height="22">-->
          </span>
          <span style="float: left"  class="logo-lg">
            <img src="assets/images/adp-large.png" alt="" height="40"> <span style="color:white; font-size: 16px; padding-left: 15px">DT Service</span>
<!--            <h2>Catalyst</h2>-->
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
<!--      <form class="app-search d-none d-lg-block">-->
<!--        <div class="position-relative">-->
<!--          <input type="text" class="form-control" placeholder="Search...">-->
<!--          <span class="bx bx-search-alt"></span>-->
<!--        </div>-->
<!--      </form>-->

<!--      <div class="dropdown-mega d-none d-lg-block ml-2" ngbDropdown>-->
<!--        <button type="button" class="btn header-item" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="false"-->
<!--          aria-expanded="false" i18n="@@megamenuButton">-->
<!--          Mega Menu-->
<!--          <i class="mdi mdi-chevron-down"></i>-->
<!--        </button>-->
<!--        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>-->
<!--          <div class="row">-->
<!--            <div class="col-sm-8">-->

<!--              <div class="row">-->
<!--                <div class="col-md-4">-->
<!--                  <h5 class="font-size-14 mt-0">UI Components</h5>-->
<!--                  <ul class="list-unstyled megamenu-list">-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Lightbox</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Range Slider</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Sweet Alert</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Rating</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Forms</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Tables</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Charts</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->

<!--                <div class="col-md-4">-->
<!--                  <h5 class="font-size-14 mt-0">Applications</h5>-->
<!--                  <ul class="list-unstyled megamenu-list">-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Ecommerce</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Calendar</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Email</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Projects</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Tasks</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Contacts</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->

<!--                <div class="col-md-4">-->
<!--                  <h5 class="font-size-14 mt-0">Extra Pages</h5>-->
<!--                  <ul class="list-unstyled megamenu-list">-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Light Sidebar</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Compact Sidebar</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Horizontal layout</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Maintenance</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Coming Soon</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Timeline</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">FAQs</a>-->
<!--                    </li>-->

<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-sm-4">-->
<!--              <div class="row">-->
<!--                <div class="col-sm-6">-->
<!--                  <h5 class="font-size-14 mt-0">UI Components</h5>-->
<!--                  <ul class="list-unstyled megamenu-list">-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Lightbox</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Range Slider</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Sweet Alert</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Rating</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Forms</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Tables</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);">Charts</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->

<!--                <div class="col-sm-5">-->
<!--                  <div>-->
<!--                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="d-flex">

<!--      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>-->
<!--        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"-->
<!--          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">-->
<!--          <i class="mdi mdi-magnify"></i>-->
<!--        </button>-->
<!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu-->
<!--          aria-labelledby="page-header-search-dropdown">-->

<!--          <form class="p-3">-->
<!--            <div class="form-group m-0">-->
<!--              <div class="input-group">-->
<!--                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">-->
<!--                <div class="input-group-append">-->
<!--                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="dropdown d-inline-block" ngbDropdown>-->
<!--        <button [routerLink]="['/app/products-publishing/cart']" type="button" class="btn header-item noti-icon">-->
<!--          <i class="bx bx-cart"></i>-->
<!--          <span class="badge badge-danger badge-pill">{{getCartLength()}}</span>-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="dropdown d-none d-lg-inline-block ml-1">-->
<!--        <button type="button" class="btn header-item noti-icon">-->
<!--          <i class="bx bx-cart"></i>-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>-->
<!--        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>-->
<!--          <i class="bx bx-customize"></i>-->
<!--        </button>-->
<!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>-->
<!--          <div class="px-lg-2">-->
<!--            <div class="row no-gutters">-->
<!--              <div class="col">-->
<!--                <a class="dropdown-icon-item" href="https://www.shopify.com/">-->
<!--                  <img src="assets/images/brands/shopify.png" alt="Shopify">-->
<!--                  <span>Shopify</span>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="col">-->
<!--                <a class="dropdown-icon-item" href="https://www.ebay.com/">-->
<!--                  <img src="assets/images/brands/ebay.png" alt="Ebay">-->
<!--                  <span>Ebay</span>-->
<!--                </a>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="dropdown-icon-item" href="javascript: void(0);">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="assets/images/brands/dribbble.png" alt="dribbble">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>Dribbble</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->

<!--&lt;!&ndash;            <div class="row no-gutters">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="dropdown-icon-item" href="javascript: void(0);">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="assets/images/brands/dropbox.png" alt="dropbox">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>Dropbox</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="dropdown-icon-item" href="javascript: void(0);">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>Mail Chimp</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="col">&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="dropdown-icon-item" href="javascript: void(0);">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="assets/images/brands/slack.png" alt="slack">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>Slack</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

<!--      <div class="dropdown d-inline-block" ngbDropdown>-->
<!--        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"-->
<!--          ngbDropdownToggle>-->
<!--          <i class="bx bx-bell bx-tada"></i>-->
<!--&lt;!&ndash;          <span class="badge badge-danger badge-pill">3</span>&ndash;&gt;-->
<!--        </button>-->
<!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"-->
<!--          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>-->
<!--          <div class="p-3">-->
<!--            <div class="row align-items-center">-->
<!--              <div class="col">-->
<!--                <h6 class="m-0"> Notifications </h6>-->
<!--              </div>-->
<!--              <div class="col-auto">-->
<!--                <a href="javascript: void(0);" class="small"> View All</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">-->
<!--            <a href="" class="text-reset notification-item">-->
<!--              <div class="media">-->
<!--                <div class="avatar-xs mr-3">-->
<!--                  <span class="avatar-title bg-primary rounded-circle font-size-16">-->
<!--                    <i class="bx bx-cart"></i>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="media-body">-->
<!--                  <h6 class="mt-0 mb-1">Your order is placed</h6>-->
<!--                  <div class="font-size-12 text-muted">-->
<!--                    <p class="mb-1">If several languages coalesce the grammar</p>-->
<!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </a>-->
<!--&lt;!&ndash;            <a href="" class="text-reset notification-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="media">&ndash;&gt;-->
<!--&lt;!&ndash;                <img src="assets/images/users/avatar-3.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="media-body">&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6 class="mt-0 mb-1">James Lemire</h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-1">It will seem like simplified English.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="" class="text-reset notification-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="media">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="avatar-xs mr-3">&ndash;&gt;-->
<!--&lt;!&ndash;                  <span class="avatar-title bg-success rounded-circle font-size-16">&ndash;&gt;-->
<!--&lt;!&ndash;                    <i class="bx bx-badge-check"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="media-body">&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6 class="mt-0 mb-1">Your item is shipped</h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-1">If several languages coalesce the grammar</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="" class="text-reset notification-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="media">&ndash;&gt;-->
<!--&lt;!&ndash;                <img src="assets/images/users/avatar-4.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="media-body">&ndash;&gt;-->
<!--&lt;!&ndash;                  <h6 class="mt-0 mb-1">Salena Layfield</h6>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--          </perfect-scrollbar>-->
<!--          <div class="p-2 border-top">-->
<!--            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">-->
<!--              <i class="mdi mdi-arrow-down-circle mr-1"></i> Load More..-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->



<!--      <div class="dropdown d-inline-block" ngbDropdown>-->
<!--&lt;!&ndash;        <div class="dropdown d-inline-block">&ndash;&gt;-->
<!--          <button type="button" class="btn header-item noti-icon right-bar-toggle" ngbDropdownToggle id="page-header-user-dropdown1">-->
<!--            <i class="bx bx-cog bx-spin"></i>-->
<!--          </button>-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
<!--          <a class="dropdown-item text-success" href="javascript: void(0);" (click)="navigateToSettings()"><i-->
<!--            class="bx bx-power-off font-size-18 align-middle mr-1 text-danger"></i>Profile</a>-->
<!--        </div>-->
<!--      </div>-->



<!--            <div class="dropdown d-inline-block">-->
<!--              <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="navigate()">-->
<!--                <i class="bx bx-cog bx-spin"></i>-->
<!--              </button>-->
<!--            </div>-->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="{{getImageLogo()}}" (error)="imageNotFoundServer($event)" alt="">
          <span class="d-none d-xl-inline-block ml-1">{{getUserName()}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
<!--          <a class="dropdown-item" href="javascript: void(0);" (click)="navigateToSettings()"><i class="bx bx-user font-size-16 align-middle mr-1"></i>-->
<!--           Edit Profile</a>-->
<!--          <a class="dropdown-item" href="javascript: void(0);"><i-->
<!--              class="bx bx-wallet font-size-16 align-middle mr-1"></i> My Wallet</a>-->
<!--          <a class="dropdown-item d-block" href="javascript: void(0);"><span-->
<!--              class="badge badge-success float-right">11</span><i-->
<!--              class="bx bx-wrench font-size-16 align-middle mr-1"></i> Settings</a>-->
<!--          <a class="dropdown-item" href="javascript: void(0);"><i-->
<!--              class="bx bx-lock-open font-size-16 align-middle mr-1"></i> Lock-->
<!--            screen</a>-->
<!--          <div class="dropdown-divider"></div>-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
        </div>
      </div>

<!--      <div class="dropdown d-inline-block">-->
<!--        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">-->
<!--          <i class="bx bx-cog bx-spin"></i>-->
<!--        </button>-->
<!--      </div>-->

    </div>
  </div>
</header>
