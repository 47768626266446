import { Injectable } from '@angular/core';

import { AuthUtil } from '../../authUtils';

import { User } from '../models/auth.models';
import {LoggerService} from '../../services/logger.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private authUtil : AuthUtil, private logger:LoggerService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return this.authUtil.getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string, storeUrl: string) {
        return this.authUtil.loginUser(email, password, storeUrl).then((response: any) => {
          this.logger.log(response);
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(userName, email, password, companyName, storeUrl, country) {
      this.logger.log("codes in auth service : " + JSON.stringify(country));
        return this.authUtil.registerUser(userName, email, password, companyName, storeUrl, country).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return this.authUtil.forgetPassword(email).then((response: any) => {
            const message = response;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
      this.authUtil.logout();
    }
}

