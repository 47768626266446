export let appConfiguration = {
  prefix : "ctl",
  ENVIRONMENT : "DEV",  //Possible-Values ['LOCAL', 'DEV', 'COMMERCIAL']


  LOCAL_RASERVICE_Url : "",
  DEV_RASERVICE_Url : "http://dev.raservice.jazzba.io/",
  COMMERCIAL_RASERVICE_Url : "https://raservice.jazzba.io/",

  LOCAL_CATALYST_Url : "",
  DEV_CATALYST_Url : "https://dev.catalystservice.anydatapoint.com/",
  // DEV_CATALYST_Url : "http://whiztec.ddns.net:8082/",
  COMMERCIAL_CATALYST_Url : "https://catalystservice.anydatapoint.com/",

  DEV_DTS_Url : "http://devdtsservice.anydatapoint.com/"
}


export let IMAGES_BASE_URL = "http://dev.cdn01.anydatapoint.com/";
export let jazzbaManagePurchasesUrl = "http://dev.manageca.jazzba.io/#/app/transactions/purchaseOrder"
export let jazzbaManageSuppliersUrl = "http://dev.manageca.jazzba.io/#/app/buy-supply/supplier"

export let copyRight = "2020"

