import { Injectable } from '@angular/core';
import { appConfiguration } from "../providers/config";
import { Observable } from "rxjs";
import { ApiResponse } from "./data";
import {HttpClient} from "@angular/common/http";
import {LoggerService} from "./logger.service";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  raServiceUrl = appConfiguration[appConfiguration.ENVIRONMENT + "_RASERVICE_Url"]
  catalystUrl = appConfiguration[appConfiguration.ENVIRONMENT + "_CATALYST_Url"]
  dtsUrl = appConfiguration[appConfiguration.ENVIRONMENT + "_DTS_Url"]

  constructor(public http: HttpClient, public logger:LoggerService) {
  }

  // loginFromcatalyst(userName, password): Observable<any> {
  //   this.logger.log("Logger");
  //   let credentials = {
  //     "userName" : userName,
  //     "password" : password,
  //   }
  //   return this.http.post<any>(this.dtsUrl + 'api/v1/adpCustomer/signIn', credentials);
  // }

  loginFromcatalyst(userName, password, storeUrl): Observable<any> {
    this.logger.log("login from catalyst called : ")
    let credentials = {
      "userName" : userName,
      "password" : password,
      // "storeUrl" : storeUrl
    }
    return this.http.post<any>(this.dtsUrl + 'api/v1/adpCustomer/signIn', credentials);
  }

  signOut(){
    // In the case of future use if we have to send logout call to the server of some clearing cache purpose
  }

  getAdpCustomerProductsFromJazzba(offset, resultcount, body):Observable<any>{

    let dtApiUrl = localStorage.getItem("dtapiUrl");

    let headers = {
      'token': localStorage.getItem("token")
    };
    // let options = new Option{
    //   [headers: "headers"]
    // };
    this.logger.log("Get products by adp customer called" + dtApiUrl);
    return this.http.post<any>(dtApiUrl + "api/v1/products/search/"+offset+"/"+resultcount+"/"+1, body, {headers})
  }

  getAdpCustomerProductsFromCatalyst(body):Observable<any>{

    let dtApiUrl = localStorage.getItem("dtapiUrl");

    let headers = {
      'Content-Type' : "application/json",
      'token': localStorage.getItem("uuid")
    };

    this.logger.log("Get products by adp customer called" + dtApiUrl);
    return this.http.post<any>(this.catalystUrl + "api/v1/products/search", body, {headers})
  }

  getProductsFromJazzbaAgainstUuid(body):Observable<any>{

    let dtApiUrl = localStorage.getItem("dtapiUrl");

    let headers = {
      'Content-Type' : "application/json",
      'token': localStorage.getItem("uuid")
    };

    this.logger.log("Get products by adp customer called" + dtApiUrl);
    return this.http.post<any>(this.catalystUrl + "api/v1/products/getProductsAgainstSupplierUuidFromJazzba", body, {headers})
  }

  // syncProductsFromJazzba():Observable<any>{
  //   this.logger.log("syncProductsFromJazzba in api service")
  //   let body = JSON.parse(localStorage.getItem("adpCustomer"));
  //
  //   let headers = {
  //     'Content-Type' : "application/json",
  //     'token': localStorage.getItem("token")
  //   };
  //   return this.http.post<any>(this.catalystUrl + "api/v1/products/syncProductsFromJazzba", body, {headers})
  // }


  syncProductsFromJazzba():Observable<any>{
    this.logger.log("syncProductsFromJazzba in api service")
    let body = {
       "userName" : localStorage.getItem("signInUserName"),
       "password" : localStorage.getItem("signInUserPassword"),
       // "uuid" : uuid
    };

    let headers = {
      'Content-Type' : "application/json",
      'token': localStorage.getItem("uuid")
    };
    return this.http.post<any>(this.catalystUrl + "api/v1/products/syncProductsFromJazzba", body, {headers})
  }


  saveProduct(body):Observable<any>{
    this.logger.log("Body before sending call : " + JSON.stringify(body))
    let headers = {
      // 'token': localStorage.getItem("token")
      'Content-Type': 'application/json'
    };

    return this.http.post<any>(this.catalystUrl + "api/v1/products/saveProductsForShowCase", body, {headers})
  }


  authenticateAdpCustomer(body){
    return this.http.post(this.catalystUrl + "api/v1/adpCustomer/auth", body);
  }

  getProductAgainstLocalId(id){
    let headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("uuid")

    };

    let options = {
      headers: headers
    }
    return this.http.get(this.catalystUrl + "api/v1/products/getProductAgainstId/" + id, options);
  }


  updateProductInCatalyst(body):Observable<any>{

    let headers = {
      'Content-Type' : "application/json",
      'token': localStorage.getItem("uuid")
    };
    // let options = new Option{
    //   [headers: "headers"]
    // };
    return this.http.post<any>(this.catalystUrl + "api/v1/products/update", body, {headers})
  }


  RegisterAdpCustomer(userName, email, password, companyName, storeUrl, countryCodeDto):Observable<any>{
    this.logger.log("register calledddddd" + countryCodeDto);

    let headers = {'Content-Type' : "application/json"};

    let body = {
          "companyName" : companyName,
          "userName" : userName,
          "password" : password,
          "email" : email,
          "country" : countryCodeDto.abbrivation

    }

    return this.http.post<any>(this.dtsUrl + "api/v1/adpCustomer/signUp", body, {headers})
  }


  getCountryCodesList(){
    let headers = {
      'Content-Type': 'application/json'
    };

    let options = {
      headers: headers
    }
    return this.http.get(this.dtsUrl + "api/v1/user/getListOfCountryCodes/0", options);
  }

  checkUserAvailability(userName){
    let headers = {'Content-Type' : "application/json"};
    return this.http.post<any>(this.catalystUrl + "api/v1/user/userAvailability?username=" + userName, {headers})
  }

  getAdpCustomerStoresList(){
    let headers = {
      'Content-Type': 'application/json',
      'token' : localStorage.getItem("uuid")
    };

    let options = {
      headers: headers
    }
    return this.http.get(this.catalystUrl + "api/v1/store/getActiveAdpCustomerStoresList", options);
  }


  pushProductsToOnlineStoresThroughCatalyst(body):Observable<any>{

    let headers = {'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")};

    // let body = {
    // }

    return this.http.post<any>(this.catalystUrl + "api/v1/products/pushProductsToOnlineStores", body, {headers})
  }


  getPushProductsAgainstAdpCustomerId(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.post(this.catalystUrl + "api/v1/products/getPushedProductsAgainstOnlineStoreId", body, {headers})
  }


  getAdpCustomerAgainstUuid(supplierUuid):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    let body = {
      "uuid" : supplierUuid
    }

    return this.http.post(this.catalystUrl + "api/v1/adpCustomer/getAdpCustomerAgainstUuid", body, {headers})
  }


  syncInventoryAgainstAdpCustomerId():Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.post(this.catalystUrl + "api/v1/products/syncInventoryFromJazzba",{headers})
  }

  getSupplierFromCatalyst():Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.get(this.catalystUrl + "api/v1/suppliers/getAllSuppliersAgainstAdpCustomerId",{headers})
  }

  updateSupplierInCatalyst(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.post(this.catalystUrl + "api/v1/suppliers/updateSupplier", body, {headers})
  }

  checkProductIfAlreadyPushed(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.post(this.catalystUrl + "api/v1/products/checkProductIfAlreadyPushed", body, {headers})
  }

  resetPassword(userName){
    this.logger.log("userName in reset password method " + userName);
    let headers = {
      'Content-Type' : "application/json"
    }

    return this.http.get(this.catalystUrl + "api/v1/user/recoverPassword/?userName=" + userName, {headers})
  }

  getStats():Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("uuid")
    }

    return this.http.get(this.catalystUrl + "api/v1/adpCustomer/getStats", {headers})
  }

  getProcessedDocuments():Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("token")
    }

    return this.http.get(this.dtsUrl + "api/v1/adpCustomer/getProcessedDocumentCount", {headers})
  }

  getProfile():Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("token")
    }

    return this.http.get(this.dtsUrl + "api/v1/adpCustomer/getProfile", {headers})
  }

  updateProfile(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("token")
    }

    return this.http.post(this.dtsUrl + "api/v1/adpCustomer/update", body,  {headers})
  }

  getTransactions(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("token")
    }

    return this.http.post(this.dtsUrl + "api/v1/adpCustomer/getLatestTransactions", body,  {headers})
  }
  getTransactionsInDateRange(body):Observable<any>{
    let headers = {
      'Content-Type' : "application/json", 'token' : localStorage.getItem("token")
    }

    return this.http.post(this.dtsUrl + "api/v1/adpCustomer/getTransactionsInDateRange", body,  {headers})
  }
}
